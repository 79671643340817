import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import {
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Výsledky navazujících projektů | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Výsledky navazujících projektů" lang="cs" />
    <meta name="keywords" content="výsledky, navazujících, projektů" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const disableGrid = {
    div: {
      maxWidth: "100%",
      flexBasis: "auto",
    },
  };

  return (
    <Layout>
      <Container>
        <Box>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={6}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="iframe"
                    src="https://www.youtube.com/embed/ESv6PG2zMfY"
                    sx={{ height: "250px" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Koleje patří vlakům 1
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Výukový film, určený především pro mladší žáky, shrnuje stručnou a přitažlivou
                      formou hlavní nebezpečí pohybu v blízkosti trati a na přejezdech a varuje před
                      rizikovým chováním. Přestože se nám znalost zásad bezpečného pohybu v
                      blízkosti železniční trati může zdát jako samozřejmost, děti a mládež nemají
                      mnoho příležitostí k jejich osvojení. Film je možné využít i jako součást
                      dopravní výchovy ve školách.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="iframe"
                    src="https://www.youtube.com/embed/5NukRC3vyZE"
                    sx={{ height: "250px" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Koleje patří vlakům 2
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Osvětový film pro studenty a starší žáky přibližuje divákovi nejčastější
                      nebezpečné situace v blízkosti trati a na přejezdech a dramatickým způsobem
                      varuje před nepozorností a rizikovým jednáním. Film působí na postoje mládeže
                      k problematice bezpečného pohybu v blízkosti trati a klade důraz na pochopení
                      důsledků nehody pro rodinu a přátele.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box
            sx={{
              borderBottom: "1px solid black",
              opacity: "10%",
              paddingTop: "90px",
            }}
          ></Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 18 }}
            sx={{ marginTop: "30px" }}
          >
            <Grid item md={5} sx={{ width: "100%" }}>
              <Box sx={disableGrid}>
                <StaticImage
                  alt="hodnotenie"
                  src="../images/metodicka-prirucka-pro-planovani-provadeni-a-hodnoceni-vzdelavacich-aktivit-pro-prevenci-neopravnenych-vstupu-osob-do-prostoru-drahy.png"
                />
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Metodická příručka pro plánování, provádění a hodnocení vzdělávacích aktivit pro
                  prevenci neoprávněných vstupů osob do prostoru dráhy
                </Typography>
                <Typography variant="body2" sx={{ marginTop: "30px" }}>
                  Příručka přehledným způsobem nabízí ucelený soubor aktivit, které je možné
                  uskutečnit pro osvětu a vzdělávání různých skupin obyvatelstva, základní zásady a
                  doporučení pro realizaci jednotlivých opatření a seznam témat, která mohou být
                  předmětem těchto aktivit. V textu jsou rovněž uvedeny limity aplikace a účinnosti
                  osvětových opatření.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginTop: "30px" }}
          >
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Metodická příručka pro plánování, provádění a hodnocení vzdělávacích aktivit pro
                  prevenci neoprávněných vstupů osob do prostoru dráhy
                </Typography>
                <Typography variant="body2" sx={{ marginTop: "30px" }}>
                  Příručka přehledným způsobem nabízí ucelený soubor aktivit, které je možné
                  uskutečnit pro osvětu a vzdělávání různých skupin obyvatelstva, základní zásady a
                  doporučení pro realizaci jednotlivých opatření a seznam témat, která mohou být
                  předmětem těchto aktivit. V textu jsou rovněž uvedeny limity aplikace a účinnosti
                  osvětových opatření.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} sx={{ width: "100%" }}>
              <Box sx={disableGrid}>
                <StaticImage
                  alt="prirucka-pro-uziti"
                  src="../images/prirucka-pro-uziti-neschudnych-rohozi-pro-prevenci-neopravnenych-vstupu-osob-do-prostoru-drahy.png"
                ></StaticImage>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
}
